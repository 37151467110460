<template>
  <div class="termService">
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col cols="12" class="title-section">
          <h2>เงื่อนไขการใช้บริการ</h2>
        </v-col>
        <v-col cols="12" class="body-section">
          การตกลงสมัครเข้าใช้บริการ ถือว่า
          ผู้ใช้บริการยอมรับว่าได้อ่านและทำความเข้าใจเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัวอย่างละเอียดถี่ถ้วนเป็นอย่างดีทั้งหมดก่อนแล้ว
          โดยผู้ใช้บริการยอมรับเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัวทุกประการ
          ซึ่งเงื่อนไขการใช้บริการฉบับนี้มีผลผูกพันผู้ใช้บริการตามกฎหมาย<br /><br />
          1. &nbsp;&nbsp; เงื่อนไขการใช้บริการของบริษัท สต็อคทูมอร์โรว์ จำกัด
          ฉบับนี้มีวัตถุประสงค์เพื่อแจ้งให้ทราบถึงสิทธิและหน้าที่ตามกฎหมายอันพึงต้องปฏิบัติตาม<br />
          2. &nbsp;&nbsp; คำจำกัดความ<br />
          &emsp; 2.1 &nbsp;&nbsp; “ผู้ให้บริการ” หมายถึง บริษัท สต็อคทูมอร์โรว์
          จำกัด ในชื่อของ “S2M-Wallet” หรือชื่ออื่นใดอันหมายถึงบริการนี้
          และให้หมายรวมถึงการงานใช้ส่วนใดส่วนหนึ่งหรือทั้งหมด ทั้ง
          โดยตรงผ่านเว็บไซต์และหรือผ่านตัวแทนในรูปแบบต่างๆ รวมทั้งตัวแทน
          และผู้เกี่ยวข้องที่ได้รับมอบหมายด้วย<br />
          &emsp; 2.2 &nbsp;&nbsp; “ผู้ใช้บริการ” หมายถึง
          บุคคลใดก็ตามที่เข้าถึงการใช้งาน เข้าใช้งาน ไม่ว่าจะเป็นผลิตภัณฑ์
          ซอฟต์แวร์ บริการ หรือเว็บไซต์ หรือแอพพลิเคชั่น หรือรูปแบบอื่นใด
          ของผู้ให้บริการก็ตาม <br />
          &emsp; 2.3 &nbsp;&nbsp; “เงื่อนไขการใช้บริการ” หมายถึง ข้อตกลง
          ข้อกำหนด ข้อสัญญา หรือชื่ออื่นใดที่มีลักษณะแบบเดียวกัน
          เพื่อแจ้งให้ทราบถึงสิทธิและหน้าที่ตามกฎหมาย <br />
          &emsp; 2.4 &nbsp;&nbsp; “การให้บริการ” หมายถึง
          การแลกเปลี่ยนเป็นสินค้าหรือใช้สิทธิประโยชน์
          ใช้บริการกับทางผู้ให้บริการ <br />
          3. &nbsp;&nbsp;
          การตัดสินใจในการลงทุนในสินทรัพย์ดิจิทัลเป็นการตัดสินใจของผู้ใช้บริการเอง
          ผู้ให้บริการมิได้ดำเนินการในฐานะเป็นที่ปรึกษาหรือผู้ดูแลผลประโยชน์ของผู้ใช้บริการในการลงทุนแต่อย่างใด
          โดยก่อนการตัดสินใจลงทุน ผู้ใช้บริการขอรับรองว่า
          ผู้ใช้บริการได้ศึกษาและพิจารณาอย่างรอบคอบถึงข้อมูลทั้งหมดที่เกี่ยวกับการลงทุนในสินทรัพย์ดิจิทัลตลอดจนปัจจัยอื่น
          ๆ ที่เกี่ยวข้องกับการลงทุนเป็นอย่างดีแล้ว
          โดยผู้ใช้บริการสมัครใจยอมรับเอาความเสี่ยงทั้งปวงจากการลงทุนเอง
          และผู้ให้บริการไม่มีหน้าที่ในการดูแลผลประโยชน์ให้แก่ผู้ใช้บริการ
          อีกทั้งไม่มีความรับผิดชอบและไม่ต้องรับผิดใด ๆ ในความรับผิด
          ข้อเรียกร้อง ความเสียหาย ความสูญเสีย ต้นทุน ผลกำไร ขาดทุน
          และค่าใช้จ่ายใด ๆ ที่เกิดขึ้นจากการลงทุนดังกล่าวทั้งสิ้น
          <br />
          4. &nbsp;&nbsp; การใช้บริการของผู้ให้บริการนั้น
          ผู้ใช้บริการเข้าใจและยอมรับข้อตกลง เงื่อนไขการใช้บริการ
          โดยผู้ให้บริการจะไม่รับผิดชอบต่อความเสียหายที่เกิดจากการใช้งานทั้งสิ้น
          <br />
          5. &nbsp;&nbsp; การสมัครเข้าใช้บริการ การยอมรับข้อตกลง
          เงื่อนไขการใช้บริการ และนโยบายความเป็นส่วนตัวต่างๆ
          ถือว่าผู้ใช้บริการยอมรับว่า ได้อ่านและเข้าใจ
          พร้อมทั้งตกลงยินยอมตามข้อตกลง เงื่อนไขการใช้บริการ
          นโยบายความเป็นส่วนตัวและหรือข้อบังคับอื่นใดแล้วแต่กรณีเป็นอย่างดีแล้ว
          <br />
          6. &nbsp;&nbsp;
          ผู้ให้บริการไม่อนุญาตให้นำเข้าและหรือส่งออกซึ่งข้อมูลใดๆ
          และ/หรือเอกสาร และ/หรือสิ่งแปลกปลอมใดๆ
          เข้ามาในระบบที่อาจจะทำให้เกิดความเสียหายแก่อุปกรณ์ ระบบ ฮาร์ดแวร์
          หรือซอฟท์แวร์ อันเป็นความผิดตามที่กฎหมายบัญญัติไว้
          <br />
          7. &nbsp;&nbsp;
          ผู้ใช้บริการต้องไม่พยายามหรือกระทำการใดๆที่เป็นอันตรายต่อความปลอดภัยของระบบคอมพิวเตอร์หรือระบบเน็ตเวิร์ค
          ซึ่งหมายรวมถึงการกระทำความผิดใดๆตามที่กฎหมายกำหนด
          <br />
          8. &nbsp;&nbsp; ระบบจะไม่มีการเก็บข้อมูลส่วนบุคคล
          รวมถึงรหัสผ่านของผู้ใช้บริการ
          โดยผู้ใช้บริการจะเป็นผู้รับผิดชอบความเสียหายที่เกิดขึ้นจากการใช้งานเองทั้งหมด
          <br />
          9. &nbsp;&nbsp;
          ผู้ให้บริการมีสิทธิเด็ดขาดแต่เพียงฝ่ายเดียวที่จะสามารถเปลี่ยนแปลง
          ข้อตกลง เงื่อนไขการใช้บริการ นโยบายความเป็นส่วนตัว ข้อกำหนด สัญญา
          และ/หรือชื่ออื่นใดอันมีความหมายเช่นเดียวกัน
          ในการให้บริการได้โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
          และไม่มีความรับผิดใดๆ ต่อคู่สัญญาและ/หรือบุคคล
          และ/หรือนิติบุคคลภายนอกทั้งสิ้น
          <br />
          10. &nbsp;&nbsp; ผู้ใช้บริการเข้าใจและยอมรับข้อตกลง
          และเงื่อนไขต่างๆของผู้ให้บริการที่ได้มีการเปลี่ยนแปลงแล้ว
          หากผู้ใช้บริการเข้าใช้บริการหลังมีการเปลี่ยนแปลง
        </v-col>
        <v-col cols="12" class="button-section">
          <v-btn
            block
            rounded
            dark
            dense
            height="49"
            color="#2CD97B"
            style="font-size:1.2rem"
            @click="$router.push('/OTP/emailVerify')"
            >ยอมรับเงื่อนไข</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TermService",
};
</script>

<style lang="scss">
.termService {
  height: 100vh;
  padding: 0 16px;
  max-width: 700px;
  margin: 0 auto;
  .title-section {
    height: 100px;
    font-size: 21px;
    display: flex;
    align-items: center;
  }
  .body-section {
    height: calc(100vh - 180px);
    overflow-y: scroll;
  }
  .button-section {
    margin: 0 auto;
    height: 80px;
    display: flex;
    max-width: 350px;
    align-items: center;
  }
}
</style>
